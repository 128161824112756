import Timeline from "./Timeline";
import Admin from "./Admin";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./RequireAuth";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Timeline />} />
      <Route
        path="/admin"
        element={
          <RequireAuth>
            <Admin />
          </RequireAuth>
        }
      />
    </Routes>
  );
}

export default App;
