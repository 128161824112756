import { ReactElement, ReactNode } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import Login from "./Login";

export interface RequireAuthProps {
  children: ReactNode;
}

export default function RequireAuth({
  children,
}: RequireAuthProps): ReactElement {
  const [token] = useLocalStorage("token");

  if (token) {
    return <>{children}</>;
  }

  return <Login />;
}
