import { Form, Formik } from "formik";
import { ReactElement } from "react";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import * as yup from "yup";
import { EVENT_TYPES, typeToEmoji } from "./utils";
import { NewEntry } from "./postgrest";

interface EntryFormValues {
  title: string;
  start: string;
  end: string;
  type: string;
}

interface NewEntryFormProps {
  onSubmit: (data: NewEntry) => void;
}

export default function NewEntryForm({
  onSubmit,
}: NewEntryFormProps): ReactElement {
  const initialValues = {
    title: "",
    start: "",
    end: "",
    type: "EVENT",
  };

  return (
    <Box>
      <Formik<EntryFormValues>
        initialValues={initialValues}
        validationSchema={yup.object({
          title: yup.string().required("A title is required"),
          start: yup
            .string()
            .matches(
              /^-?\d{4}-\d{2}-\d{2}$/,
              "Please enter a valid date in the format YYYY-MM-DD"
            )
            .required("A start date is required"),
          end: yup
            .string()
            .matches(
              /^(-?\d{4}-\d{2}-\d{2})|ongoing$/,
              "Please enter a valid date in the format YYYY-MM-DD or 'ongoing'"
            ),
          type: yup.string().oneOf(EVENT_TYPES).required("A type is required"),
        })}
        onSubmit={(values, formik) => {
          onSubmit(values);
          formik.resetForm();
          formik.setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <Stack direction="row" spacing={2}>
              <TextField
                id="title"
                name="title"
                label="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
              <TextField
                id="start"
                name="start"
                label="Start Date YYYY-MM-DD"
                value={formik.values.start}
                onChange={formik.handleChange}
                error={formik.touched.start && Boolean(formik.errors.start)}
                helperText={formik.touched.start && formik.errors.start}
              />
              <TextField
                id="end"
                name="end"
                label="End Date (nothing, YYYY-MM-DD, or 'ongoing')"
                value={formik.values.end}
                onChange={formik.handleChange}
                error={formik.touched.end && Boolean(formik.errors.end)}
                helperText={formik.touched.end && formik.errors.end}
              />
              <TextField
                id="type"
                name="type"
                label="Type"
                value={formik.values.type}
                onChange={formik.handleChange}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                select
              >
                {EVENT_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Stack direction="row" spacing={1}>
                      <Box>{typeToEmoji(type)}</Box>
                      <Box>{type}</Box>
                    </Stack>
                  </MenuItem>
                ))}
              </TextField>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={formik.isSubmitting}
              >
                Add
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
