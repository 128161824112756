import { ReactElement } from "react";
import { HistoryTimeline } from "./HistoryTimeline";
import { useHistoryTimelineData } from "./UseHistoryTimelineData";

export default function Timeline(): ReactElement {
  const { data } = useHistoryTimelineData();

  if (!data) {
    return <div>Loading...</div>;
  }

  return <HistoryTimeline entries={data} />;
}
