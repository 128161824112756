import { ReactElement } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { HistoryTimelineEntry } from "./HistoryTimelineEntry";
import { EVENT_TYPES, typeToEmoji } from "./utils";
import { Box } from "@mui/material";

interface EditEntriesGridProps {
  data: HistoryTimelineEntry[];
  onUpdate: (
    newData: HistoryTimelineEntry,
    oldData: HistoryTimelineEntry
  ) => HistoryTimelineEntry | Promise<HistoryTimelineEntry>;
  onDelete: (id: number) => void;
}

export default function EditEntriesGrid({
  data,
  onUpdate,
  onDelete,
}: EditEntriesGridProps): ReactElement {
  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      editable: true,
      flex: 1,
    },
    {
      field: "start",
      headerName: "Start Date",
      editable: true,
      align: "right",
    },
    {
      field: "end",
      headerName: "End Date",
      editable: true,
      align: "right",
    },
    {
      field: "type",
      headerName: "Type",
      renderCell: (
        params: GridRenderCellParams<HistoryTimelineEntry, string>
      ) => <Box>{typeToEmoji(params.value || "")}</Box>,
      editable: true,
      align: "center",
      type: "singleSelect",
      valueOptions: EVENT_TYPES.map((type) => ({
        value: type,
        label: `${typeToEmoji(type)} -️ ${type}`,
      })),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params: GridRowParams<HistoryTimelineEntry>) => [
        <GridActionsCellItem
          key={params.id}
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => onDelete(parseInt(`${params.id}`))}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <DataGrid
      rows={data}
      columns={columns}
      disableRowSelectionOnClick
      processRowUpdate={onUpdate}
      sx={{ maxWidth: "1200px" }}
    />
  );
}
